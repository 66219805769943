import React, { Fragment } from "react"
import PageLayout from '../../../layouts/page-layout';
import {graphql} from "gatsby";

const Page = ({data}) => {
  return (
    <PageLayout
      data={data} title="GNG Computers Ltd"
      description="Near Huntingdon, Cambridgeshire"
    >
      <p>GNG Computers offers a local repair service for phones, tablets, PCs and laptops. Alongside this we also sell high quality refurbished computers, monitors, peripherals and servers.</p>
      <div className="row">

        <div className="span5">
          <p><strong>Address:</strong></p>
          <address>
            GNG Computers Ltd<br />
            2 Kestrel Place<br />
            Hinchingbrooke Business Park<br /> Huntingdon<br />
            Cambridgeshire<br />
            PE29 6FQ<br />
          </address>

          <span title="Phone"><strong>Phone:</strong> 01480 437 614</span><br />
		<span title="Website"><strong>Website:</strong><br />
				www.gngcomputers.com
				</span><br />
		<span title="Website"><strong>Twitter:</strong><br />
				<a href="http://twitter.com/gngcomputers">@gngcomputers</a>
				</span><br />
        </div>

        <div className="span3">
          <img alt="Recommended site"
               src="/img/badges/recomm2-200.png"/><br />

        </div>
      </div>


    </PageLayout>
  );
};

export default Page;

export const query = graphql`
  query {
    profile: profileYaml {
      ...ProfileFragment
    }
    site {
      siteMetadata {
        title,
        showThemeLogo
      }
    }
  }
`
